export const onRouteUpdate = ({ location }) => {
  sessionStorage.setItem('previousPath', sessionStorage.getItem('currentPath'));
  sessionStorage.setItem('currentPath', location.pathname);

  const studentModalNextPathname = sessionStorage.getItem('studentModalNextPathname');

  if (studentModalNextPathname === location.pathname) {
    sessionStorage.setItem('studentModalParam', 'true');
    sessionStorage.setItem('studentModalNextPathname', '');
  } else {
    sessionStorage.setItem('studentModalParam', 'false');
    sessionStorage.setItem('studentModalNextPathname', '');
  }
};
