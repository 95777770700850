import Cookies from 'js-cookie';

/**
 * they query param name used, e.g.
 * https://www.generali.at/privatkunden/haftung-rechtsschutz/haftpflichtversicherung/?y=Y008253
 *
 * @type {string}
 */
const agentKey = 'y';

/**
 * Name of the cookie holding the agentId
 * this MUST be the same as used in the different JSX components
 *
 * @type {string}
 */
const cookieName = 'agentID';

/**
 * Additional cookie attributes
 * this SHOULD be the same as used in the different JSX components
 */
const cookieAttributes = { expires: 365, domain: '.generali.at', path: '/' };

function setAgentCookie() {
  const params = new URLSearchParams(window.location.search);
  const agentID = params.get(agentKey);
  if (!agentID) {
    return;
  }

  Cookies.set(cookieName, agentID, cookieAttributes);

  // if y is the only parameter, clean it up
  if (window.location.search === `?${agentKey}=${agentID}`) {
    window.history.replaceState(null, document.title, window.location.pathname);
  }
}

setAgentCookie();
